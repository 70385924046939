<template>
    <div>

        <div class="notice">

            <div class="header">
                <div class="image">
                    <a href="/">
                        <img style="width: 90px;height: 61px;margin:auto" src="@/assets/images/logo.png" alt="">
                    </a>
                </div>
                <div class="header-title">
                    <p>{{ type == 1 ? '公告' : '规则' }}</p>
                </div>
            </div>
            <div class="main">
                <ul class="main-ul">
                    <li v-for="item in tableData" :key="item.Id" @click="noticeDatail(item.Id)">
                        <div class="li-content">

                            <div class="li-title">
                                {{ item.Title }}
                            </div>
                            <div class="li-createtime">
                                {{ item.CreateTime }}
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
            <div class="block">

                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="[10, 50]" :page-size="100" layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>

        </div>
    </div>
</template>


<script>
import Service from "@/utils/service";
export default {
    name: "NoticeList",
    components: {},

    props: [],
    data() {
        return {
            tableData: [],
            pageIndex: 1,
            pageSize: 10,
            type: '',
            keyword: '',
            total: 0,
        }
    },
    methods: {
        async refreshList() {
            let result = await Service.getNoticeList({
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                type: this.type,
                keyword: this.keyword,

            });
            this.total = result.Total;
            this.tableData = result.Rows;

        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.refreshList();
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.refreshList();
        },
        noticeDatail(Id) {
            this.$router.push({
                path: "noticeDetail",
                query: {
                    id: Id,
                }
            })
        },

    },
    mounted() {
        if (this.$route.query.Type) {

            this.type = this.$route.query.Type;
        }
        this.refreshList();
    },

}



</script>
<style lang="scss" scoped>
.notice {
    margin: auto;
    margin-top: 10px;
    width: 1200px;
    border: 1px solid #ebeef5;
    box-sizing: border-box;

    .header {
        display: flex;
        padding: 15px 0 0 15px;
        font-size: 20px;
        line-height: 40px;
        border-bottom: 1px solid #ebeef5;

        .iamge {
            width: 90px;
        }

        .header-title {
            margin-left: 40px;
        }
    }

    .block {
        margin-bottom: 15px;
    }

    .main {
        .main-ul {
            li {
                margin-bottom: 10px;
                line-height: 40px;
                border-bottom: 1px solid #eee;

                font-size: 13px;
                color: #666;

                padding: 0 20px;
                position: relative;
                cursor: pointer;

                .li-content {
                    display: flex;
                    justify-content: space-between;
                    margin-right: 10px;

                }
            }

            li:hover {
                color: #2d8cf0;
            }
        }
    }

}
</style>