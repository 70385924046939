<template>
  <div class="logo-container-vertical">
    <a href="/">
      <img width="89px" height="60px" :src="require('@/assets/images/logo-title.png')" alt="">
    </a>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Logo",
  data() {
    return {

    };
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@mixin container {
  position: relative;
  height: 65px;
  overflow: hidden;
  line-height: 65px;
  background: #ffffff;
}


.logo-container-vertical {


  @include container;
  margin-top: 36px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  margin-right: 30px;
}
</style>
