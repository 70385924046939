var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.dialogVisible,"width":"500px","close-on-click-modal":false,"title":"快速竞价"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-image',{attrs:{"src":_vm.item.Image,"fit":"contain"}}),_c('el-form',{ref:"item",staticClass:"demo-ruleForm",attrs:{"model":_vm.item,"label-width":"100px"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"商品名称："}},[_c('div',{staticClass:"product-name"},[_vm._v(_vm._s(_vm.item.Name))]),_c('el-divider')],1),_c('el-form-item',{attrs:{"label":"原价："}},[_c('span',[_vm._v(_vm._s(_vm.item.OriginalPriceDesc))]),_c('el-divider')],1),_c('el-form-item',{attrs:{"label":"清仓价："}},[_c('span',[_vm._v(_vm._s(_vm.item.PriceDesc))]),_c('el-divider')],1),_c('el-form-item',{attrs:{"label":"库存："}},[_c('span',[_vm._v(_vm._s(_vm.item.Stock))]),_c('el-divider')],1),_c('el-form-item',{attrs:{"label":"起订量："}},[_c('span',[_vm._v(_vm._s(_vm.item.MinOrder))]),_c('el-divider')],1),_c('el-form-item',{attrs:{"label":"您的报价：","prop":"BuyPrice","rules":[
                { required: true, message: '请填写报价' },
                { validator: _vm.validatePrice },

            ]}},[_c('el-input',{directives:[{name:"number",rawName:"v-number"}],attrs:{"placeholder":"请输入您的报价","autocomplete":"off"},model:{value:(_vm.item.BuyPrice),callback:function ($$v) {_vm.$set(_vm.item, "BuyPrice", $$v)},expression:"item.BuyPrice"}})],1),_c('el-form-item',{attrs:{"label":"竞价数量：","prop":"BuyNum","rules":[
                { required: true, message: '请填写竞价数量' },
                { validator: _vm.validateNumOrder },
            ]}},[_c('el-input',{directives:[{name:"number",rawName:"v-number"}],attrs:{"placeholder":"请输入竞价数量","autocomplete":"off"},model:{value:(_vm.item.BuyNum),callback:function ($$v) {_vm.$set(_vm.item, "BuyNum", $$v)},expression:"item.BuyNum"}})],1),_c('el-form-item',{staticClass:"btn"},[_c('el-button',{staticStyle:{"width":"120px","height":"40px","border":"1px solid #cccccc","margin-right":"30px","border-radius":"2px","color":"#999999","font-size":"16px"},on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"width":"120px","height":"40px","background":"#009dff","border-radius":"2px","font-size":"16px"},attrs:{"type":"primary","native-type":"submit"},on:{"click":function($event){return _vm.submit('item')}}},[_vm._v("确认")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }