<template>
  <component :is="this.item.children?.length ? 'Submenu' : 'MenuItem'" :item="item" :path="path">
    <template v-if="item.children?.length">
      <side-bar-item v-for="child in item.children" :key="Array.isArray(child.path) ? child.path[0] : child.path" :path="Array.isArray(child.path) ? child.path[0] : child.path" :item="child" />
    </template>
  </component>
</template>

<script>
import Util from "@/utils/util";

export default {
  name: "SideBarItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.vab-nav-icon {
  margin-right: 4px;
}

::v-deep {
  .el-tag {
    float: right;
    height: 16px;
    padding-right: 4px;
    padding-left: 4px;
    margin-top: 17px;
    line-height: 16px;
    border: 0;
  }
}
</style>
