<template>
  <div class="app-main-container">
    <transition mode="out-in" name="fade-transform">
      <router-view :key="key" class="app-main-height" />

    </transition>
  </div>
</template>

<script>
export default {
  name: "AppMain",
  data() {
    return {
      show: true,
      title: "",

      keepAliveMaxNum: 100,
    };
  },

  computed: {
    visitedRoutes() {
      return this.$store.state.visitedRoutes;
    },
    cachedRoutes() {
      const cachedRoutesArr = [];
      this.visitedRoutes.forEach((item) => {
        if (!item.meta.noKeepAlive) {
          cachedRoutesArr.push(item.name);
        }
      });
      return cachedRoutesArr;
    },
    key() {
      return this.$route.fullPath;
    },
  },
  watch: {
    $route: {
      handler(route) { },
      immediate: true,
    },
  },
  created() { },
  mounted() { },
  methods: {},
};
</script>

<style lang="scss" scoped>
.app-main-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.app-main-height {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
