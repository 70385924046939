<template>
    <el-dialog :visible.sync="dialogVisible" width="500px" :close-on-click-modal="false" title="快速竞价">
        <el-image :src="item.Image" fit="contain"></el-image>
        <el-form :model="item" ref="item" label-width="100px" class="demo-ruleForm" @submit.native.prevent>
            <el-form-item label="商品名称：">
                <div class="product-name">{{ item.Name }}</div>
                <el-divider></el-divider>
            </el-form-item>
            <el-form-item label="原价：">
                <span>{{ item.OriginalPriceDesc }}</span>
                <el-divider></el-divider>
            </el-form-item>
            <el-form-item label="清仓价：">
                <span>{{ item.PriceDesc }}</span>
                <el-divider></el-divider>
            </el-form-item>
            <el-form-item label="库存：">
                <span>{{ item.Stock }}</span>
                <el-divider></el-divider>
            </el-form-item>
            <el-form-item label="起订量：">
                <span>{{ item.MinOrder }}</span>
                <el-divider></el-divider>
            </el-form-item>
            <el-form-item label="您的报价：" prop="BuyPrice" :rules="[
                { required: true, message: '请填写报价' },
                { validator: validatePrice },

            ]">
                <el-input v-model="item.BuyPrice" placeholder="请输入您的报价" v-number autocomplete="off"></el-input>

            </el-form-item>
            <el-form-item label="竞价数量：" prop="BuyNum" :rules="[
                { required: true, message: '请填写竞价数量' },
                { validator: validateNumOrder },
            ]">
                <el-input v-model="item.BuyNum" v-number placeholder="请输入竞价数量" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item class="btn">
                <el-button @click="close()" style="width: 120px;
height: 40px;
border: 1px solid #cccccc;
             margin-right: 30px;
border-radius: 2px;color:#999999;font-size: 16px;">取消</el-button>
                <el-button type="primary" native-type="submit" @click="submit('item')" style="width: 120px;
height: 40px;
background: #009dff;
border-radius: 2px;font-size: 16px;">确认</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
  
<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import EditContact from '@/views/contact/EditContact'

export default {
    name: 'BidDialog',
    components: {},
    props: ["refresh"],
    data() {
        return {
            dialogVisible: false,
            item: {
                BuyPrice: "",
                BuyNum: "",
            },

        }

    },
    methods: {
        open(item) {
            this.item = item;
            this.dialogVisible = true;
        },
        close() {
            this.dialogVisible = false;

        },
        async submit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let data = {
                        ProductId: this.item.Id,
                        ProductNum: this.item.BuyNum,
                        Price: this.item.BuyPrice,
                    };
                    await Service.createBidding(data);
                    this.$message.success("竞价成功");
                    this.close();
                    this.refresh();
                } else {
                    return false;
                }
            });
        },
        validatePrice(val, value, callback) {
            if (value < 0) {
                callback(new Error('请输入有效报价'))
            }
            else {
                callback()
            }
        },
        validateNumOrder(val, value, callback) {
            if (!value > 0) {
                callback()
            }
            else {

                if (Math.floor(value) != value) {
                    callback(new Error('竞价数量只能为整数，请修改'))
                }
                if (value < Number(this.item.MinOrder)) {
                    callback(new Error('低于起订数量，请修改'))
                } else if (value > Number(this.item.Stock)) {
                    callback(new Error('大于库存数量，请修改'))
                }
                else {
                    callback()
                }
            }
        },


    },

    async mounted() {
        let subscribeResult = await Service.checkSubscribe();
        if (subscribeResult.Subscribe) {
            let checkAddResult = await Service.checkAddProduct();
            if (!checkAddResult.CanAddProduct) {
                var dialog = Util.createComponent(
                    EditContact,
                    {
                        title: "完善用户信息",
                        hint: "为方便后续沟通，请补充联系方式",
                        showBack: false,
                    },

                    document.querySelector("#app")
                );
                dialog.open();
            }
        }
    }

}


</script>
<style lang="scss" scoped>
::v-deep {
    .el-dialog {

        border-radius: 10px;

        .el-dialog__header {
            .el-dialog__headerbtn {
                .el-dialog__close {
                    font-size: 20px;
                }
            }
        }

        .el-dialog__header::before {
            content: "";
            width: 460px;
            height: 1px;
            background-color: #dcdfe6;
            display: block;
            position: relative;
            top: 35px;
        }

        .el-dialog__body {
            border: 0 !important;
        }
    }
}


.el-image {
    margin-bottom: 20px;
    width: 100%;
    height: 200px;
}

.el-form-item {

    .product-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.btn {
    text-align: center;

    ::v-deep {
        .el-form-item__content {
            margin-left: 25px !important;
            padding-top: 20px;

        }
    }
}



::v-deep {
    .el-divider {
        margin: 0;
    }
}
</style>