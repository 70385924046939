import Request from "@/utils/request";

export default {
  checkSubscribe() {
    return Request.get("/api/User/CheckSubscribe", null, true);
  },
  checkAuthCode(data) {
    return Request.get("/api/WxGzh/CheckAuthCode", data, false);
  },
  checkAddProduct() {
    return Request.get("/api/Product/CheckAddProduct", null, true);
  },

  getQrCode() {
    return Request.get("/api/WxGzh/GetQrCode");
  },
  getQrCodeStatus(data) {
    return Request.get("/api/WxGzh/GetQrCodeStatus", data, false);
  },
  // 联系方式
  getContact() {
    return Request.get("/api/User/GetUserDetail", null, true);
  },
  editContact(data) {
    return Request.postJson("/api/User/EditUser", data, true);
  },

  // 首页列表
  homeGetProductList(data) {
    return Request.get("/api/Home/GetProductList", data, true);
  },

  getHome(data) {
    return Request.get("/api/Home/GetHome", data);
  },

  report(data) {
    return Request.postJson("/api/Common/Report", data);
  },

  setPassword(data) {
    return Request.postJson("/api/Admin/Account/SetPassword", data, true);
  },
  // 上传图片
  uploadImage(data) {
    return Request.postForm("/api/Common/uploadimage", data);
  },

  //User

  // 地址
  getAddressList(data) {
    return Request.get("/api/Address/GetAddressList", data, true);
  },
  getAddressDetail(data) {
    return Request.get("/api/Address/GetAddressDetail", data, false);
  },
  editAddress(data) {
    return Request.postJson("/api/Address/EditAddress", data, false);
  },
  deleteAddress(data, showLoading) {
    return Request.postJson("/api/Address/DeleteAddress", data, showLoading);
  },

  // 产品
  getProductList(data) {
    return Request.get("/api/Product/GetProductList", data, true);
  },

  getProductDetail(data) {
    return Request.get("/api/Product/GetProductDetail", data, true);
  },

  editProduct(data) {
    return Request.postJson("/api/Product/EditProduct", data, true);
  },
  deleteProduct(data) {
    return Request.postJson("/api/Product/DeleteProduct", data, true);
  },
  setProductFlag(data) {
    return Request.postJson("/api/Product/SetProductFlag", data, true);
  },

  // 订单
  getOrderList(data) {
    return Request.get("/api/Order/GetOrderList", data, true);
  },
  getOrderDetail(data) {
    return Request.get("/api/Order/GetOrderDetail", data, true);
  },
  addOrderImage(data) {
    return Request.postJson("/api/Order/AddOrderImage", data);
  },

  payOrder(data) {
    return Request.postJson("/api/Order/PayOrder", data, true);
  },
  shipOrder(data) {
    return Request.postJson("/api/Order/ShipOrder", data, true);
  },
  cancelOrder(data) {
    return Request.postJson("/api/Order/CancelOrder", data, true);
  },
  completeOrder(data) {
    return Request.postJson("/api/Order/CompleteOrder", data, true);
  },

  deleteOrderImage(data) {
    return Request.postJson("/api/Order/DeleteOrderImage", data);
  },

  //竞价
  getBiddingList(data) {
    return Request.get("/api/Bidding/GetBiddingList", data, true);
  },
  setBiddingFlag(data) {
    return Request.postJson("/api/Bidding/SetBiddingFlag", data, true);
  },
  createBidding(data) {
    return Request.postJson("/api/Bidding/CreateBidding", data, true);
  },
  createOrder(data) {
    return Request.postJson("/api/Order/CreateOrder", data, true);
  },
  //公告
  getNoticeList(data) {
    return Request.get("/api/Home/GetNoticeList", data, true);
  },
  getNoticeDetail(data) {
    return Request.get("/api/Home/GetNoticeDetail", data, true);
  },
  //支付

  getPayQrCodeStatus(data) {
    return Request.get("/api/WxPay/GetQrCodeStatus", data, false);
  },

  //求购
  getAskToBuyList(data) {
    return Request.get("/api/Home/GetAskToBuyList", data, false);
  },

  //产品详情
  getHomeProductDetail(data) {
    return Request.get("api/Home/GetProductDetail", data, false);
  },
};
