<template>
    <div>
        <div class="notice-detail">
            <div class="header">

                <div class="image">
                    <a href="/">
                        <img style="width: 90px;height: 61px;margin:auto" src="@/assets/images/logo.png" alt="">
                    </a>
                </div>
                <div>
                    <div class="title">
                        <h1>{{ content.Title }}</h1>
                    </div>
                    <div class="time">

                        <p> <span>日期：</span> {{ content.CreateTime }} </p>
                    </div>

                </div>
            </div>
            <div class="main" v-html="content.Content"> </div>


        </div>
    </div>
</template>
  
<script>
import Service from "@/utils/service";

export default {
    name: "NoticeDeatil",
    components: {},

    props: [],
    data() {
        return {
            Id: '',
            content: '',
        }
    },
    methods: {
        async refreshList() {
            let result = await Service.getNoticeDetail({
                id: this.Id

            });
            this.content = result;

        },
    },
    mounted() {
        if (this.$route.query.id) {
            this.Id = this.$route.query.id
        }

        this.refreshList()
    },
}



</script>
<style lang="scss" scoped>
.notice-detail {
    width: 1200px;
    margin: auto;

    .header {

        padding-top: 30px;
        border-bottom: 1px solid #e5e5e5;

        .image {
            position: absolute;
            margin-top: -15px;
            width: 90px;

        }

        .title {

            text-align: center;

            h1 {
                margin: 0;
            }
        }

        .time {
            color: #999;
            padding: 0 40px;
            text-align: right;
        }
    }

    .main {
        padding-top: 20px;
    }
}
</style>