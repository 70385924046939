<template>
  <el-dialog :visible.sync="dialogVisible" :title="title" width="400px" :close-on-click-modal="false" append-to-body :close-on-press-escape="false" :show-close="false">
    <div class="hint">{{ hint }}</div>
    <el-form :model="item" ref="item" @submit.native.prevent label-width="80px">
      <el-form-item label="电话" prop="Phone" :rules="[
        { required: true, message: '电话不能为空' },
      ]">
        <el-input v-model="item.Phone"></el-input>

      </el-form-item>
      <el-form-item required label="微信号" prop="Wechat" :rules="[
        { required: true, message: '微信号不能为空' },

      ]">
        <el-input v-model="item.Wechat"></el-input>

      </el-form-item>
      <el-form-item class="btn">

        <el-button type="primary" native-type="submit" @click="onSubmit('item')">保存</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Service from "@/utils/service";
export default {
  name: 'userListedit',
  components: {},
  props: ["refresh", "title", "hint",],
  data() {
    return {
      item: {
        Phone: '',
        Wechat: '',
      },

      dialogVisible: false,
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.getContact()
    },

    async getContact() {
      let response = await Service.getContact();
      this.item.Phone = response.Phone;
      this.item.Wechat = response.Wechat;
    },
    async onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = {
            Phone: this.item.Phone,
            Wechat: this.item.Wechat,
          };

          await Service.editContact(data);
          this.$message.success("修改成功");
          this.dialogVisible = false;
        } else {
          return false;
        }
      });



    },

  }
}



</script>
<style lang="scss" scoped>
.el-form {
  &>div:last-child {
    text-align: right;
    padding-top: 10px;
    margin-bottom: 0;
  }
}

.hint {
  text-align: center;
  font-size: 18px;
  margin-top: -10px;
  margin-bottom: 20px;
}

::v-deep {
  .el-dialog__header {
    text-align: center;
  }
}

::v-deep {
  .el-dialog {
    border-radius: 10px;

    .el-dialog__header::before {
      content: "";
      width: 360px;
      height: 1px;
      background-color: #dcdfe6;
      display: block;
      position: relative;
      top: 35px;
    }

    .el-dialog__body {
      border: 0 !important;
    }
  }
}
</style>