<template>
  <div style="display:flex">
    <el-breadcrumb class="breadcrumb-container breadcrumb" separator="/">
      <el-breadcrumb-item v-for="item in list" :key="item.path">
        {{ item }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div id="translateGoogle" style="margin-left: 20px;"> </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      list: this.getBreadcrumb(),
      notice: null,
    };
  },

  watch: {
    $route() {
      this.list = this.getBreadcrumb();
    },
    "$store.state.menu": {
      handler() {
        this.list = this.getBreadcrumb();
      }
    },

  },
  methods: {
    getBreadcrumb() {
      let path = this.$route.fullPath;

      return this.$store.state.components.find((v) => {
        if (Array.isArray(v.path)) {
          return v.path.some(p => path.startsWith(p))
        } else {
          return path.startsWith(v.path)
        }
      })?.fullTitle ?? [];
    }
  },
  mounted() {
    let translateParent = document.querySelector("#translateGoogle");
    let translateGoogle = document.querySelector("#google_translate_element");
    if (translateGoogle) {
      translateParent.appendChild(translateGoogle);
    }
  }

};
</script>

<style lang="scss" scoped>
.breadcrumb {
  font-size: 24px;
}

.breadcrumb-container {
  ::v-deep {
    .el-breadcrumb__item {
      .el-breadcrumb__inner {
        a {
          display: flex;
          float: left;
          font-weight: normal;
          color: #515a6e;

          i {
            margin-right: 3px;
          }
        }
      }

      &:last-child {
        .el-breadcrumb__inner {
          color: black;

          a {
            color: #999;
          }
        }

      }
    }
  }
}
</style>
