import '@/assets/css/app.scss'
import '@/assets/font/iconfont.css'
import { Message } from 'element-ui'

const install = (Vue, opts = {}) => {
    Vue.prototype.$baseTitle = process.env.VUE_APP_TITLE;

    Vue.directive('focus', {
        inserted(el, binding, vnode) {
            el.children[0].focus();
        },
    });
    Vue.directive('number', {
        bind(el, binding, vnode) {
            el.oninput = () => {
                let value = el.children[0].value;
                if (!value) {
                    return;
                }
                let temp = value.toString();
                temp = temp.replace(/(?<=^-?)\./, '0.');
                if (!/^-?\d*\.?\d*$/g.test(temp)) {
                    Message({
                        message: '请输入正确的数值',
                        type: 'error'
                    })
                    temp = temp.replace(/[^\d.-]/g, '');
                    temp = temp.replace(/^-/g, '$#$').replace(/-/g, '').replace('$#$', '-');
                    temp = temp.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
                }
                if (temp != value) {
                    el.children[0].value = temp;
                    vnode.data.model.callback(temp);
                }

            }
        }
    })
}


if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue)
}

export default install
