<template>
  <el-submenu ref="subMenu" :index="item.path" :popper-append-to-body="false">
    <template slot="title">
      <i v-if="item.icon" :class="item.icon"></i>
      <span>{{ item.title }}</span>
    </template>
    <slot></slot>
  </el-submenu>
</template>

<script>
import Util from "@/utils/util";

export default {
  name: "Submenu",
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },

  },
  methods: {

  },
};
</script>
<style lang="scss" scoped></style>