<template>
  <el-menu-item :index="Array.isArray(item.path) ? item.path[0] : item.path" @click="onClick">
    <i v-if="item.icon" :class="item.icon"></i>
    <span v-else class="dot">
      <i class="el-icon-star-on" style="transform: translate(-50%,-136%);   font-size: 19px;"></i>
    </span>
    <span>{{ item.title }}</span>
  </el-menu-item>
</template>

<script>
import Util from "@/utils/util";

export default {
  name: "MenuItem",
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      before: false,
    }
  },

  methods: {
    onClick() {
      const path = this.item.path;
      const target = this.item.target;
      const type = this.item.type;
      if (type === "action") {
        return;
      } else {
        if (target === "_blank") {
          if (Util.isAbsolutePath(path)) {
            window.open(path);
          } else {
            let routeData = this.$router.resolve(path);
            window.open(routeData.href);
          }
        } else {
          if (Util.isAbsolutePath(path)) {
            window.location.href = path;
          } else {
            if (Array.isArray(path)) {
              this.$router.push(path[0])
            } else {
              this.$router.push(path);
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
}
</style>