<template>
  <div class="nav-bar-container">
    <breadcrumb />
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
    };
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
    visitedRoutes() {
      return this.$store.state.visitedRoutes;
    },
    routes() {
      return this.$store.state.routes;
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.nav-bar-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 80px;
  padding-right: 20px;
  padding-left: 20px;
  overflow: hidden;
  user-select: none;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);


}
</style>
