<template>
    <div class="customer-service">
        <div class="contact">
            <img :src="require('@/assets/images/contact.png')" alt="">
            <div>
                <span>如需帮助，请扫码联系客服</span>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "CustomerService",
    data() {
        return {

        };
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
.customer-service {
    width: 170px;
    position: relative;
    top: 140px;
    left: 50%;
    transform: translateX(-50%);
    // bottom: 50px;
    text-align: center;

    .contact {
        div {
            text-align: center;
        }
    }

    img {
        width: 150px;
    }
}
</style>
  