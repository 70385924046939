<template>
    <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="340px" :close-on-click-modal="false">

        <div class="main">
            <div class="title">联系我们</div>
            <el-image style="" :src="require('@/assets/images/contact.png')">
            </el-image>
            <div class="foot">
                <div>手机/微信/Whatsapp: +86 13537648506</div>
                <div>Email: qingcang111@gmail.com</div>
                <div>地址:广东省深圳市宝安区新桥街道</div>

            </div>
        </div>
    </el-dialog>
</template>
  
<script>
import Service from "@/utils/service";
export default {
    name: 'CustomerService',
    components: {},
    props: ["refresh"],
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        open() {
            this.dialogVisible = true;
        }

    }
}



</script>
<style lang="scss" scoped>
.main {
    color: #333;
    font-weight: bold;

    .title {
        font-size: 27px;
        margin-bottom: 25px;
    }

    .el-image {
        width: 250px;
    }

    .foot {
        font-size: 14px;
        padding-left: 25px;

        div {
            margin-top: 15px;
            text-align: left !important;
        }
    }
}


::v-deep {
    .el-dialog {
        border-radius: 10px;

        .el-dialog__header {
            .el-dialog__headerbtn {
                .el-dialog__close {
                    font-size: 20px;
                }
            }
        }

        .el-dialog__body {
            padding-top: 20px;
            position: relative;
            border-top: 0;

            div {
                text-align: center;
            }
        }
    }
}
</style>